import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import makeBlockie from "ethereum-blockies-base64";
import { shortenaddress } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/global";
import { zeroAddress } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/prepends";

const Titles = ({ details, isLoading, collection_address }) => {

    return (
        <div>
            <div className="flex items-start justify-between">
                <div className="w-full">
                    <a href={"/nft/" + collection_address} className="font-semibold truncate text-blue-500 hover:text-blue-400\ cursor-pointer">{details?.collection?.name ? details?.collection?.name : ""}</a>
                    <p className="text-4xl md:text-6xl font-bold mt-2 truncate">{details?.name ? details?.name : details?.token_id ? "#" + details?.token_id : "-"}</p>
                    {!isLoading ? (
                        <div className="flex items-center gap-x-2 mt-4">
                            <p className="text-sm">Owner: </p>
                            <a href={"/address/" + details?.top_ownerships[0]?.owner?.address} className="flex items-center gap-x-2 bg-dark900 px-4 py-2 rounded-full border border-dark900">
                                <img src={makeBlockie(details?.top_ownerships[0]?.owner?.address ? details?.top_ownerships[0]?.owner?.address : zeroAddress)} className="w-5 h-5 rounded-full" />
                                <div className="flex items-center gap-x-1">
                                    <p className="text-main text-sm font-semibold">{shortenaddress(details?.top_ownerships[0]?.owner?.address)}</p>

                                </div>
                            </a>
                        </div>
                    ) : (null)}
                </div>
                <div className="flex items-center gap-x-2">
                    {/* <Tooltip content={"Transfer"} placement="top" className="bg-dark700 py-2 truncate px-4 rounded-2xl font-semibold" >
                        <div className="w-10 h-10 bg-dark700 rounded-2xl flex justify-center items-center border-0 border-dark700" onClick={() => setTransferModal(true)}>
                            <FontAwesomeIcon icon={['fas', 'fa-paper-plane']} className="text-neutral-400 text-md" />
                        </div>
                    </Tooltip> */}
                    {/* <Tooltip content={"Refresh metadata"} placement="top" className="bg-dark700 py-2 truncate px-4 rounded-2xl font-semibold" > */}
                    {/* <div className="w-10 h-10 bg-dark700 rounded-2xl flex justify-center items-center border-0 border-dark700" onClick={() => refreshMetadata()}>
                        <FontAwesomeIcon icon={['fas', 'fa-rotate']} className="text-neutral-400 text-md" />
                    </div> */}
                    {/* </Tooltip> */}

                </div>
            </div>
        </div>
    );

}

export default Titles;
