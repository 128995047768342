import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";

const Feed = ({ }) => {

    useEffect(() => {
        document.getElementById("title").innerHTML = "Home - Sphere";
    }, []);

    return (
        <div>
        </div>
    );

}

export default Feed;
