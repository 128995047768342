import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import { ConnectWallet } from "../../1.components/2.wallet/connectWallet";
import { BlockiesGif } from "../../1.components/2.wallet/connectDialog";
import BackgroundImg from "../../0.resources/3.files/images/bg1.png";
import Logo from "../../0.resources/3.files/logo/logo_trans2.png";
const Login = ({ }) => {

    useEffect(() => {

    }, []);

    return (
        <div className="w-full flex justify-between items-center pb-0" style={{ backgroundImage: `url()`, backgroundPosition: "center center", backgroundSize: "cover" }}>
            <div className="w-full h-full rounded-2xl flex items-center justify-center" style={{ backgroundImage: `url(${BackgroundImg})`, backgroundPosition: "center center", backgroundSize: "cover" }}>
                <p className="text-4xl font-medium">Where web3 comes together</p>
            </div>

            <div className="bg-dark950 h-full">
                <ConnectDialog />
            </div>
        </div>
    );

}

export default Login;


const ConnectDialog = ({ }) => {

    return (
        <div className="flex justify-center items-center w-full h-full">
            <div className="flex flex-col justify-start items-start bg-gray-100 dark:bg-dark950 w-[500px] mx-5 md:mx-0 py-20 px-10 md:px-20 rounded-2xl" style={{}}>
                {/* <BlockiesGif /> */}
                <img src={Logo} className="w-20 h-20" />
                <div className="pt-10">
                    <p className="text-3xl font-bold">Welcome to Sphere!</p>
                    <p className="text-md mt-3 text-gray-500 dark:text-dark400">On Sphere, you can:</p>
                    <div className="mt-6">
                        <div className="flex items-center gap-x-2 rounded-full px-0 py-1 w-fit mt-2 cursor-pointer">
                            <div className="bg-gray-500 dark:bg-dark700 w-5 h-5 flex justify-center items-center rounded-full">
                                <FontAwesomeIcon icon={['fas', 'fa-check']} className="text-gray-500 dark:text-dark400" size="xs" />
                            </div>
                            <p className="text-md text-gray-500 dark:text-dark400">Meet like-minded frens</p>
                        </div>
                        <div className="flex items-center gap-x-2 rounded-full px-0 py-1 w-fit mt-2 cursor-pointer">
                            <div className="bg-gray-500 dark:bg-dark700 w-5 h-5 flex justify-center items-center rounded-full">
                                <FontAwesomeIcon icon={['fas', 'fa-check']} className="text-gray-500 dark:text-dark400" size="xs" />
                            </div>
                            <p className="text-md text-gray-500 dark:text-dark400">Manage your digital profile</p>
                        </div>
                        <div className="flex items-center gap-x-2 rounded-full px-0 py-1 w-fit mt-2 cursor-pointer">
                            <div className="bg-gray-500 dark:bg-dark700 w-5 h-5 flex justify-center items-center rounded-full">
                                <FontAwesomeIcon icon={['fas', 'fa-check']} className="text-gray-500 dark:text-dark400" size="xs" />
                            </div>
                            <p className="text-md text-gray-500 dark:text-dark400">Participate in the Web3 economy</p>
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <ConnectWallet />
                </div>
            </div>
        </div>
    );

}