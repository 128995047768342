import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import CollectionItems from "./partials/items";
import CollectionActivity from "./partials/activity";

const CollectionLower = ({ collection_address, tabSelected }) => {

    return (
        <div>
            {tabSelected == "items" ? (
                <CollectionItems collection_address={collection_address} />
            ) : (
                <CollectionActivity collection_address={collection_address} />
            )}
        </div>
    );

}

export default CollectionLower;
