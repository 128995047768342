import React, { useState, useContext, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { colors } from "../../../../../0.resources/1.css/colors";
import { ethToUsd } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/currencyConversion";

const Price = ({ isLoading, details, collection_address, tokenid }) => {
    const [usdPrice, setUsdPrice] = useState(null);

    async function init() {
        if (details?.seaport_sell_orders != null) {
            setUsdPrice(await ethToUsd(details?.seaport_sell_orders[0]?.current_price / 1e18));
        }
    }
    useEffect(() => {
        if (details != null) {
            init()
        }
    }, [details]);

    return (
        <div className="bg-dark900 rounded-2xl mt-6 px-8 py-10" >
            <p className="font-semibold ml-0 text-md text-white">Current Price</p>

            <div className="gap-5 mt-1 rounded-b-xl" >
                {isLoading ? (
                    <GetSkeletons />
                ) : details?.seaport_sell_orders ?
                    details?.seaport_sell_orders.length != 0 ? (
                        <div className="">
                            <p className="text-dark500 text-md" >Listed by the owner</p>
                            <div className="mt-6" style={{}} >

                                <p className="font-bold text-4xl">{details?.seaport_sell_orders[0]?.current_price / 1e18} ETH</p>
                                <p className="text-dark500 ml-0 text-lg">({usdPrice})</p>

                            </div>
                        </div>
                    ) :
                        (null) : (
                        <p className="text-gray-500 mt-1">This item is not listed</p>
                    )}

                <div className="mt-8">
                    <a href={`https://opensea.io/assets/ethereum/${collection_address}/${tokenid}`} target="_blank" className="bg-blue-500 w-fit text-white font-semibold py-3 px-6 rounded-full flex items-center gap-x-2 cursor-pointer" >
                        <p>View on Opensea</p>
                        <FontAwesomeIcon icon={['fas', 'arrow-right']} className="" />
                    </a>
                </div>
            </div>
        </div>
    );

}

export default Price;


const GetSkeletons = ({ }) => {

    return (
        <div>
            <SkeletonTheme baseColor={colors.dark950} highlightColor={colors.dark900}>
                <div style={{ width: "15%" }}>
                    <Skeleton count={1} style={{ padding: "2%", borderRadius: "50px", }} />
                </div>
                <div className="onlyvertcenter" style={{ marginTop: "1%", alignItems: "baseline" }}>
                    <div style={{ width: "15%" }}>
                        <Skeleton count={1} style={{ padding: "6%", borderRadius: "50px" }} />
                    </div>
                    <div style={{ width: "10%", marginLeft: "1%" }}>
                        <Skeleton count={1} style={{ padding: "6%", borderRadius: "50px" }} />
                    </div>
                </div>
                <div className="onlyvertcenter" style={{ marginTop: "2%" }}>
                    <div style={{ width: "20%" }}>
                        <Skeleton count={1} style={{ padding: "6%", borderRadius: "50px" }} />
                    </div>
                    <div style={{ width: "20%", marginLeft: "1%" }}>
                        <Skeleton count={1} style={{ padding: "6%", borderRadius: "50px" }} />
                    </div>
                </div>
            </SkeletonTheme>
        </div>
    )
}