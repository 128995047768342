import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import CollectionUpper from "./partials/upper";
import CollectionLower from "./partials/lower";
import { useParams } from 'react-router-dom';

const Collection = ({ }) => {
    const { collection_address } = useParams();
    const [tabSelected, setTabSelected] = useState("items");

    return (
        <div className="w-full h-full">
            <CollectionUpper collection_address={collection_address} tabSelected={tabSelected} setTabSelected={setTabSelected} />
            <CollectionLower collection_address={collection_address} tabSelected={tabSelected} />
        </div>
    );

}

export default Collection;
