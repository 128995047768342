import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import { callW3Api } from "../../../../../0.resources/2.js/0.global/3.api/callW3Api";
import { calculateZeroes, formatinusd } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import { ethers } from "ethers";
import { zeroAddress } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/prepends";
import { timeToString } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/time";
import { shortenaddress } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/global";
import makeBlockie from "ethereum-blockies-base64";

const Activity = ({ address, details }) => {
    const [items, setItems] = useState([]);

    async function init() {
        let result = await callW3Api("/token/activity", { address: address });
        console.log(result);

        setItems(result.result);
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <div className="">
            <p className="text-lg font-semibold">Activity</p>
            <p className="text-md mt-2 text-zinc-500 dark:text-dark500">Latest activity on the Blockchain</p>

            {items?.length > 0 ? (
                items?.map((item, index) => (
                    <div key={index}>
                        <ActivityMapping item={item} address={address} details={details} />
                    </div>
                ))
            ) : (null)}
        </div>
    );

}

export default Activity;

const ActivityMapping = ({ item, address, details }) => {

    return (
        <div className="flex items-center gap-x-8 py-4 border-b border-dark800 hover:bg-dark900">
            <div className="flex items-center gap-x-2 w-2/12">
                <FontAwesomeIcon icon={['fas', 'fa-arrow-right']} className="text-dark500 text-md" />
                <p className="text-md text-gray-500 dark:text-dark500">{"Transfer"}</p>
            </div>
            <a className="flex items-center gap-x-4 w-3/12" href={"/token/" + address}>
                <img src={details?.logo} className="w-10 h-10 rounded-lg" />
                <div className="truncate">
                    <p className="text-lg font-semibold truncate">{details?.symbol}</p>
                    <p className="text-md text-gray-500 dark:text-dark500">{details?.name}</p>
                </div>
            </a>
            <div className="w-2/12">
                <p className="text-blue-500 flex items-center gap-x-2">
                    <img src={makeBlockie(item?.from_address)} className="w-6 h-6 rounded-full" />
                    <span>{shortenaddress(item?.from_address)}</span>
                </p>
            </div>
            <div className="w-2/12">
                <p className="text-blue-500 flex items-center gap-x-2">
                    <img src={makeBlockie(item?.to_address)} className="w-6 h-6 rounded-full" />
                    <span>{shortenaddress(item?.to_address)}</span></p>
            </div>
            <div className="w-2/12">
                <p className="text-white">{(parseFloat(item?.value_decimal))?.toFixed(2)} {details?.symbol}</p>
            </div>
            <a className="w-2/12 flex items-center gap-x-2" target="_blank" href={"https://etherscan.io/tx/" + item?.transaction_hash}>
                <p className="text-main">{timeToString(new Date(item?.block_timestamp).getTime())}</p>
                <FontAwesomeIcon icon={['fas', 'fa-chevron-right']} className="text-main text-md" />
            </a>
        </div>
    )
}