import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import { zeroAddress } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/prepends";
import { callW3Api } from "../../../../../0.resources/2.js/0.global/3.api/callW3Api";
import { shortenaddress } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/global";
import { timeToString } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/time";
import makeBlockie from "ethereum-blockies-base64";

const Activity = ({ collection_address, tokenid }) => {
    const [activity, setActivity] = useState([]);

    async function init() {
        let result = await callW3Api("/nft/token/activity", { address: collection_address, tokenid: tokenid });

        console.log(result);
        setActivity(result);
    }

    useEffect(() => {
        init();
    }, []);

    let css = {
        "header": "text-sm font-semibold text-zinc-500 dark:text-dark500",
        div: "w-1/5 flex items-center justify-start gap-x-2 truncate"
    }
    let titles = ["Type", "From", "To", "Price", "Time"]

    return (
        <div className="bg-dark900 rounded-2xl px-6 py-6 mt-8">
            <p className="text-md font-semibold">Activity</p>
            <p className="text-md mt-2 text-zinc-500 dark:text-dark500">{activity.length > 0 ? "Latest activity on the Blockchain" : "There is no activity for this item"}</p>
            {activity.length > 0 ? (
                <div className="overflow-x-scroll">
                    <div className="min-w-[800px] md:w-full">
                        <div className="pl-4 py-4 flex item-center justify-between border-b-2 border-zinc-200 dark:border-dark800">
                            {titles.map((item, index) => (
                                <div key={index} className={index == 0 ? "w-[100px]" : css.div} >
                                    <p className={css.header}>{item}</p>
                                </div>
                            ))}
                        </div>

                        {activity.map((item, index) => (
                            <div className="pl-4 py-4 flex item-center justify-between border-b border-zinc-200 dark:border-dark800" key={index}>
                                <div className={"flex items-center gap-x-2 w-[100px]"}>
                                    {item.from_address == zeroAddress ? (
                                        <FontAwesomeIcon icon={['fas', 'fa-bolt-lightning']} className="text-zinc-500 dark:text-dark500" />
                                    ) : (
                                        <FontAwesomeIcon icon={['fas', 'right-left']} className="text-zinc-500 dark:text-dark500" />
                                    )}
                                    <p className="text-zinc-500 dark:text-dark500">{item.from_address == "0x0000000000000000000000000000000000000000" ? "Mint" : "Sale"}</p>
                                </div>
                                <div className={css.div}>
                                    <a href={"/collection_address/" + item?.from_address} className="text-blue-500 flex items-center gap-x-2">
                                        <img src={makeBlockie(item?.from_address ? item?.from_address : zeroAddress)} className="w-5 h-5 rounded-full" />
                                        {shortenaddress(item?.from_address)}</a>
                                </div>
                                <div className={css.div}>
                                    <a href={"/collection_address/" + item?.to_address} className="text-blue-500 flex items-center gap-x-2">
                                        <img src={makeBlockie(item?.to_address ? item?.to_address : zeroAddress)} className="w-5 h-5 rounded-full" />
                                        {shortenaddress(item?.to_address)}</a>
                                </div>
                                <div className={css.div}>
                                    <p>{(item?.total_price / 1e18).toFixed(4)} ETH</p>
                                </div>
                                <div className={css.div}>
                                    <a href={"https://etherscan.io/tx/" + item?.transaction?.transaction_hash} target="_blank" className="text-main">{timeToString(new Date(item?.event_timestamp + "Z").getTime())}</a>
                                    <FontAwesomeIcon icon={['fas', 'chevron-right']} className="text-main text-sm" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                null
            )}
        </div>
    );

}

export default Activity;
