import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import ProfileUpper from "./partials/upper";
import ProfileLower from "./partials/lower";
import { useParams } from "react-router-dom";
import { callW3Api } from "../../0.resources/2.js/0.global/3.api/callW3Api";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../0.resources/2.js/0.global/1.firebase/firebase";

const Profile = ({ }) => {
    const { account_address } = useParams();
    const [sectionSelected, setSectionSelected] = useState("posts");
    const [details, setDetails] = useState(null);
    const [username, setUsername] = useState(null);
    const [oss, setOss] = useState(null);
    // useEffect(() => {
    //     console.log("header height: " + document.getElementById("header").offsetHeight);
    //     document.getElementById("sidebar").style.top = (document.getElementById("header").offsetHeight + 16) + "px";
    // }, []);

    async function init(type) {
        let startTime = performance.now();
        console.time("profile");
        let result1 = await callW3Api(`/profile/${type}`, { address: account_address });
        console.log(result1);
        // setDetails(result1);

        // let result = await getDoc(doc(db, "profiles", "details", account_address.toLowerCase(), "details"));
        // console.log(result.data());
        console.timeEnd("profile");
        let endTime = performance.now();
        let elapsedTime = endTime - startTime;

        setDetails(elapsedTime);



    }

    async function init2(type) {
        let startTime = performance.now();
        console.time("username");
        let result2 = await callW3Api(`/profile/${type}`, { address: account_address });
        console.log(result2);
        // setUsername(result2);

        // let result = await getDoc(doc(db, "profiles", "details", account_address.toLowerCase(), "username"));
        // console.log(result.data());
        console.timeEnd("username");
        let endTime = performance.now();
        let elapsedTime = endTime - startTime;

        setUsername(elapsedTime);

    }

    async function os() {
        let startTime = performance.now();
        console.time("opensea");
        const options = { method: 'GET', headers: { Accept: 'application/json', 'X-API-KEY': 'a4fe8c6e08874716ab2db84cab28fb44' } };

        let result = await (await fetch('https://api.opensea.io/api/v1/account/0x591F8a2deCC1c86cce0c7Bea22Fa921c2c72fb95', options)).json();
        console.log(result);
        console.timeEnd("opensea");
        let endTime = performance.now();
        let elapsedTime = endTime - startTime;

        setOss(elapsedTime);
    }


    // useEffect(() => {
    //     init();
    //     init2();
    // }, []);
    return (
        <div className="flex justify-between gap-x-8">
            <div>
                <p className="">Username: {username ? username : ""}</p>
                <p className="">Profile: {details ? details : ""}</p>
                <p className="">OS: {oss ? oss : ""}</p>

                <button className="bg-blue-500 px-6 py-2 rounded-full mt-6" onClick={() => { init("details"); init2("username"); }}>Refresh</button>

                <button className="bg-blue-500 px-6 py-2 rounded-full mt-6" onClick={() => { init("details2"); init2("username2"); }}>Refresh2</button>
                <button className="bg-blue-500 px-6 py-2 rounded-full mt-6" onClick={() => { os() }}>OS</button>

            </div>

            {/* <div>
                <ProfileUpper account_address={account_address} details={details} username={username} sectionSelected={sectionSelected} setSectionSelected={setSectionSelected} />
                <ProfileLower account_address={account_address} details={details} username={username} sectionSelected={sectionSelected} />
            </div> */}
            <div>
                <div className="lg:w-[400px] sticky" id="sidebar">
                </div>
            </div>
        </div>
    );

}

export default Profile;
