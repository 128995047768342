import axios, * as others from 'axios';

export async function callW3Api(urlpath, body) {
    // let url = process.env.REACT_APP_API_URL + urlpath;
    let url = "https://sphere-api-eef167071724.herokuapp.com" + urlpath;
    let response = await axios({
        method: 'post',
        url: url,
        data: body
    });

    // console.log(response.data);
    return response.data.value;
}
//u8GpB!XM_:aQ>OD