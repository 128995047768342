import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import { callW3Api } from "../../../../../../0.resources/2.js/0.global/3.api/callW3Api";
import InfiniteScroll from "react-infinite-scroll-component";

const CollectionItems = ({ collection_address }) => {

    return (
        <div className="">
            <Items collection_address={collection_address} />
        </div>
    );

}

export default CollectionItems;

const Items = ({ collection_address }) => {
    let limit = 20;
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    async function fetchNext() {
        console.log("fetching next")
        if (hasMore) {
            console.log("fetching more")
            let result = await callW3Api("/nft/collection/assets", { address: collection_address, limit: limit, skip: items.length % limit });
            console.log(result);

            setItems([...items, ...result.nfts]);
            console.log(result.nfts.length);
            if (result.nfts.length < limit) {
                setHasMore(false);
            }
            console.log(result.nfts.length < limit);
        }
    }

    useEffect(() => {
        fetchNext();
    }, []);

    return (
        <div>
            {items.length > 0 ? (
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchNext}
                    hasMore={hasMore}
                    loader={<ItemsSkeleton />}
                    scrollThreshold={0.7}
                >
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 md:gap-10 mt-8 w-full ">
                        {items.map((item, index) => (
                            <div key={index} className="w-full " >
                                <ItemsMap item={item} collection_address={collection_address} />
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>
            ) : (<ItemsSkeleton />)}
        </div>
    )
}

const ItemsMap = ({ item, collection_address }) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <a href={`/nft/${collection_address}/${parseInt(item?.id?.tokenId)}`} className="w-full cursor-pointer">
            <div className="bg-white dark:bg-dark900 hover:dark:bg-dark800 border-2 dark:border border-gray-200 dark:border-dark800 rounded-2xl w-full" >
                <div className="flex justify-center items-center px-2 pt-2 w-full">
                    <img src={item?.media[0]?.thumbnail} className="rounded-xl" style={{ display: imageLoaded ? "block" : "none", width: "100%", height: "100%" }} onLoad={() => setImageLoaded(true)} />
                    <div className="animate-pulse rounded-xl w-full aspect-square bg-dark700" style={{ display: imageLoaded ? "none" : "block" }}></div>
                </div>
                <div className="w-full mt-4 px-3 pb-3">
                    <p className="text-sm font-semibold truncate text-dark400">{item?.contractMetadata?.name}</p>
                    <p className="text-md font-semibold truncate text-white">{item?.metadata?.name ? item?.metadata?.name : "#" + parseInt(item?.id?.tokenId)}</p>
                </div>
                <div className="pb-10 border-t-2 dark:border-t border-gray-200  dark:border-dark700">
                </div>
            </div>
        </a>
    )
}

export const ItemsSkeleton = ({ }) => {
    const [tokens, setTokens] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

    return (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 md:gap-10 mt-8 w-full ">
            {tokens.map((name, index) => (
                <div key={index} className="w-full " >
                    <div className="bg-white dark:bg-dark900 border-2 dark:border border-gray-200 dark:border-dark800 rounded-2xl w-full" >
                        <div className="flex justify-center items-center px-2 pt-2 w-full">
                            <div className="animate-pulse rounded-xl w-full aspect-square bg-dark800" style={{}}></div>
                        </div>
                        <div className="w-full mt-4 px-3 pb-3">
                            <div className="animate-pulse rounded-xl w-full aspect-square bg-dark800 w-20 h-4" style={{}}></div>
                            <div className="animate-pulse rounded-xl w-full aspect-square bg-dark800 w-40 h-4 mt-2" style={{}}></div>
                        </div>
                        <div className="pb-10 border-t-2 dark:border-t border-gray-200  dark:border-dark800">
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}