import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";

const Details = ({ details }) => {

    let css = {
        div: "rounded-xl px-6 py-6 w-full bg-dark900",
        req: "text-dark500 text-sm mb-1",
        res: "text-white text-md"
    }

    return (
        <div className="mt-8 md:rounded-0 md:px-0 py-0">
            {/* <div className="pt-4 px-3 rounded-t-xl flex justify-between">
                <div className="flex items-center">
                    <FontAwesomeIcon icon={['fas', 'fa-table-list']} className="text-gray-500" style={{ fontSize: "90%" }} />
                    
                </div>
                <div>
                    <FontAwesomeIcon icon={['fas', 'fa-angle-down']} className="text-gray-500" style={{ fontSize: "90%", marginTop: "0.25%" }} id="arrowicon" />
                </div>
            </div > */}

            {/* <p className="font-semibold text-white text-md">Details</p>
            <p className="text-dark500 text-md md:text-md mt-1">Blockchain information</p> */}

            <div>
                <div className="grid grid-cols-3 gap-5 rounded-b-xl mt-4 pt-0 border-t-0 border-t-gray-200" >

                    <div className={css.div}>
                        <p className={css.req}>Token ID</p>
                        <p className={css.res}>#{details?.token_id}</p>
                    </div >
                    <div className={css.div}>
                        <p className={css.req}>Standard</p>
                        <p className={css.res}>Erc 721</p>
                    </div >
                    <div className={css.div}>
                        <p className={css.req}>Blockchain</p>
                        <p className={css.res}>Ethereum</p>
                    </div >
                    {/* <div className={css.div} >
                            <p className={css.req}>Contract Address</p>
                            <a className={css.res}>
                                {window.location.pathname.includes("name") ? shortenaddress(Variables().wnsErc721Addr) : shortenaddress(Variables().wnsMembershipAddr)}
                            </a>
                        </div > */}
                </div >
                {/* <a href={"https://opensea.io/assets/ethereum/0xba9bbea08241845013b40a061e4a77c9345e4562/" + details?.token_id} target={"_blank"} className="flex items-center gap-x-2 mt-4 pt-4 border-t-2 border-gray-200">
                    <p className="text-main">View on OpenSea</p>
                    <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className="text-main" style={{ fontSize: "90%" }} />
                </a> */}
            </div>


        </div >
    );

}

export default Details;
