import React, { useState, useContext, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
// import EmptyImg from "../../../../../0.resources/3.files/images/empty_nft.png";

const Image = ({ details }) => {
    let { address } = useAccount();

    return (
        <div className="" >
            <img src={details?.image_url} className="rounded-2xl" style={{ width: "100%", height: "100%" }} />
        </div>
    );

}

export default Image;

