import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import TokenSidebar from "./partials/sidebar";
import TokenMain from "./partials/main";
import { useParams } from "react-router-dom";
import { callW3Api } from "../../../../0.resources/2.js/0.global/3.api/callW3Api";
import Activity from "./partials/activity";

const TokenPage = ({ }) => {
    const { address } = useParams();
    const [details, setDetails] = useState(null);

    async function init() {
        let result = await callW3Api("/token/details", { address: address });
        result = result.data[Object.keys(result.data)[0]];

        setDetails(result);
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <div className="w-full mt-2">

            <div className="flex gap-x-5">
                <div className="w-8/12">
                    <TokenMain address={address} details={details} />
                </div>
                <div className="w-4/12">
                    <TokenSidebar address={address} details={details} />
                </div>
            </div>

            <div className="mt-8">

                <Activity address={address} details={details} />
            </div>
        </div>
    );

}

export default TokenPage;
