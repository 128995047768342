import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import { callW3Api } from "../../../../../0.resources/2.js/0.global/3.api/callW3Api";
import { formatinusd } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import EtherscanImg from "../../../../../0.resources/3.files/images/etherscan.png";
import makeBlockie from "ethereum-blockies-base64";
import { shortenaddress } from "../../../../../0.resources/2.js/0.global/0.smallfunctions/global";

const TokenSidebar = ({ address, details }) => {

    return (
        <div className="px-6">
            {/* <WalletSwap /> */}

            <div className="flex items-center gap-x-2">
                {details?.logo ? (
                    <img src={details?.logo} className="w-12 h-12 rounded-lg" />
                ) : (
                    <img src={makeBlockie(address)} className="w-12 h-12 rounded-lg" />
                )}
                <div>
                    <p className="text-md font-bold">{details?.symbol ? details?.symbol : shortenaddress(address)}</p>
                    <p className="text-sm font-semibold text-dark500">{details?.name ? details?.name : shortenaddress(address)}</p>
                </div>
            </div>

            {details?.description ? (
                <p className="text-md font-normal text-dark500 mt-4">{details?.description.split(".")[0]}.</p>
            ) : (
                <p className="text-md font-normal text-dark500 mt-4">{details?.name ? details?.name : shortenaddress(address)} ({details?.symbol ? details?.symbol : shortenaddress(address)}) is a cryptocurrency and operates on the Ethereum platform.</p>
            )}

            <div className="mt-6">
                <Links details={details} address={address} />
            </div>

            <div className="mt-6">
                <Stats details={details} />
            </div>

            <div className="mt-8">
                <button className="w-full py-3 bg-blue-500 rounded-full text-white font-semibold">
                    Trade {details?.symbol}
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} className="ml-2" />
                </button>
            </div>
        </div>
    );

}

export default TokenSidebar;

let links = [
    {
        name: "Website",
        icon: <FontAwesomeIcon icon={['fas', 'globe']} className="text-xl" />,
    },
    {
        name: "Twitter",
        icon: <FontAwesomeIcon icon={['fab', 'twitter']} className="text-xl" />,
    },
    {
        name: "Telegram",
        icon: <FontAwesomeIcon icon={['fab', 'telegram']} className="text-xl" />,
    },
    {
        name: "Discord",
        icon: <FontAwesomeIcon icon={['fab', 'discord']} className="text-xl" />,
    },
    {
        name: "Etherscan",
        icon: <img src={EtherscanImg} className="w-[20px]" />
    }
]
const Links = ({ details, address }) => {

    return (
        <div className="flex gap-x-4">
            {links.map((link, i) => (
                <a key={i} className="flex items-center gap-x-2" href={
                    link.name == "Website" ? details?.urls?.website[0] :
                        link.name == "Twitter" ? details?.urls?.twitter[0] :
                            link.name == "Telegram" ? details?.urls?.chat ? getLink(details?.urls?.chat, "telegram") : "" :
                                link.name == "Discord" ? details?.urls?.chat ? getLink(details?.urls?.chat, "discord") : "" :
                                    link.name == "Etherscan" ? `https://etherscan.io/token/${address}` : ""
                } target="_blank">
                    {link.icon}
                </a>
            ))
            }
        </div>
    );
}

function getLink(links, type) {
    if (type == "discord") {
        for (let i = 0; i < links.length; i++) {
            if (links[i].includes("discord")) {
                return links[i];
            }
        }
    } else if (type == "telegram") {
        for (let i = 0; i < links.length; i++) {
            if (links[i].includes("t.me")) {
                return links[i];
            }
        }
    }
    return "";
}

const Stats = ({ details }) => {
    const [stats, setStats] = useState({});

    async function init() {
        let result = await callW3Api(`/token/stats`, { id: details?.id });

        setStats(result);
    }

    useEffect(() => {
        if (details != null) {
            init();
        }
    }, [details]);

    let css = {
        req: "text-sm font-normal mt-2 text-gray-500 dark:text-dark500",
        res: "text-lg font-semibold mt-2"
    }
    return (
        <div className="flex flex-col gap-y-4">
            <div>
                <p className={css.req}>Total Supply</p>
                <p className={css.res}>{stats?.total_supply ? stats?.total_supply.toLocaleString() : "-"}</p>
            </div>
            <div>
                <p className={css.req}>Market Cap</p>
                <p className={css.res}>{stats?.quote?.USD?.market_cap ? formatinusd(stats?.quote?.USD?.market_cap) : "-"}</p>
            </div>
            <div>
                <p className={css.req}>24hr Volume</p>
                <p className={css.res}>{stats?.quote?.USD?.volume_24h ? formatinusd(stats?.quote?.USD?.volume_24h) : "-"}</p>
            </div>
            <div>
                <p className={css.req}>24hr Change</p>
                <p className={`text-lg font-semibold mt-2 ${stats?.quote?.USD?.percent_change_24h ? stats?.quote?.USD?.percent_change_24h > 0 ? "text-green-500" : "text-red-500" : "text-dark500"}`}>{stats?.quote?.USD?.percent_change_24h ? (stats?.quote?.USD?.percent_change_24h).toFixed(2) + "%" : "-"}</p>
            </div>

        </div>
    )
}