import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import { callW3Api } from "../../0.resources/2.js/0.global/3.api/callW3Api";
import { formatinusd, usdToEth } from "../../0.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import CryptoImg from "../../0.resources/3.files/images/crypto.png";

const NFTs = ({ }) => {

    let items = [
        {
            name: "Bored Ape Yatch Club",
            image: "https://i.seadn.io/gae/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB?auto=format&dpr=1&w=384",
            address: "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d"
        },
        {
            name: "CryptoPunks",
            image: "https://i.seadn.io/gae/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE?auto=format&dpr=1&w=384",
            address: "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb"
        },
        {
            name: "Azuki",
            image: "https://i.seadn.io/gae/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT?auto=format&dpr=1&w=384",
            address: "0xed5af388653567af2f388e6224dc7c4b3241c544"
        },
        {
            name: "Cool Cats",
            image: "https://i.seadn.io/gae/LIov33kogXOK4XZd2ESj29sqm_Hww5JSdO7AFn5wjt8xgnJJ0UpNV9yITqxra3s_LMEW1AnnrgOVB_hDpjJRA1uF4skI5Sdi_9rULi8?auto=format&dpr=1&w=384",
            address: "0x1a92f7381b9f03921564a437210bb9396471050c"
        },
    ]

    return (
        <div className="w-full">
            <div className="px-4 py-4">
                <Carousel array={items} />
            </div>

            <div className="rounded-0 mt-4 bg-dark900 overflow-scroll w-full">
                <div className="w-[1280px]">
                    <div className="mt-0  px-5 lg:px-14 py-12">
                        <p className="text-white text-4xl font-bold px-6">Top Collections</p>
                        <p className="text-dark500 text-lg mt-2 px-6 font-semibold">Top 10 NFT collections</p>
                        <Top type={"hot"} />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default NFTs;

const Carousel = ({ array }) => {

    return (
        <div className="flex items-center justify-between gap-x-4">
            {array.map((item, index) => (
                <a key={index} className="cursor-pointer" href={`/nft/${item.address}`}>
                    <img src={item.image} className="w-[384px] rounded-2xl bg-black hover:scale-105" />
                    {/* <div className="flex items-center justify-center mt-4">
                        <p className="text-white text-lg font-semibold">{item.name}</p>
                    </div> */}
                </a>
            )
            )}
        </div>
    )
}

const Top = ({ type }) => {
    const [items, setItems] = useState([])

    async function getItems() {
        let erc20MarketcapResult = await callW3Api("/nfts/top", { type: type });
        console.log(erc20MarketcapResult);

        setItems(erc20MarketcapResult.contracts);
    }

    useEffect(() => {
        getItems();
    }, [])

    return (
        <div className="">
            {items.length != 0 ? (
                <div className="flex items-center justify-between">
                    <div className="w-full pr-10">
                        <Erc20Label type={""} />
                        <div className="mt-5">
                            <GetErc721Mapping array={items.slice(0, 5)} skip={1} />
                        </div>
                    </div>
                    <div className="w-full pl-10 border-l-2 border-dark900">
                        <Erc20Label type={""} />
                        <div className="mt-5">
                            <GetErc721Mapping array={items.slice(5, 10)} skip={6} />
                        </div>
                    </div>
                </div>
            ) : (null)}
        </div>
    )
}

const GetErc721Mapping = ({ array, skip }) => {

    const mapping = array.map((item, index) =>
        <a key={index} className="flex items-center gap-x-4 py-5 hover:bg-dark800 px-6 rounded-2xl" href={`/nft/` + item?.contract_address}>
            <div className="w-[30px]">
                <p className="text-left text-xl font-semibold text-gray-500">{skip + index}</p>
            </div>
            <div className="w-10/12 flex items-center gap-x-4 ">
                <div className="w-10 h-10 md:w-20 md:h-20 rounded-2xl flex-none" style={{ backgroundImage: `url(${item?.metadata?.thumbnail_url})`, backgroundPosition: "center center", backgroundSize: "cover" }}>
                    {/* <img src={item?.metadata?.thumbnail_url} className="rounded-lg" /> */}
                </div>
                <div className="flex items-center justify-between w-full">
                    <div className="">

                        <p className="hidden md:block text-white text-lg font-bold flex truncate">{item?.name}</p>
                        <p className="block md:hidden text-white text-lg font-bold flex truncate">{item.name.length > 10 ? item.name.substring(0, 10) + "..." : item.name}</p>

                        <p className="text-dark500 text-md">{item?.name}</p>
                    </div>
                    {/* <div className="flex items-center gap-x-8">
                        <div className="flex flex-col items-end">
                            <EthValue value={item.floor_price_usd} />
                            <p className="text-gray-500 text-sm">({formatinusd(item.floor_price_usd)})</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </a>
    );
    return mapping;
}

const EthValue = ({ value }) => {
    const [usd, setUsd] = useState(0);

    async function init() {
        let result = await usdToEth(value);
        setUsd(result);
    }

    useEffect(() => {
        init();
    }, [])

    return (
        <p className="text-lg font-semibold">{usd} ETH</p>
    )
}

const Erc20Label = ({ type }) => {

    return (
        <div className="flex items-center gap-x-4 mt-8 px-6" >
            <div className="w-[30px]">
                <p className="text-left text-md font-semibold text-dark500">{"#"}</p>
            </div>
            <div className="w-11/12 flex items-center justify-between">
                <p className="text-black text-md font-semibold flex truncate text-dark500">{type == "erc20" ? "Token" : "Collection"}</p>
                {/* <div className="flex items-center gap-x-8">
                    <p className="text-black text-md font-semibold flex truncate text-dark500">{type == "erc20" ? "Price" : "Floor Price"}</p>
                    {type == "erc20" ? (
                        <div className="opacity-0">
                            <FontAwesomeIcon icon={["fas", "fa-chevron-right"]} className="text-dark500" />
                        </div>
                    ) : (null)}

                </div> */}
            </div>
        </div >
    )
}
