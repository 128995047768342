import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import { useParams } from 'react-router-dom';
import Titles from "./partials/titles";
import { callW3Api } from "../../../../0.resources/2.js/0.global/3.api/callW3Api";
import Image from "./partials/image";
import Price from "./partials/price";
import Details from "./partials/details";
import Activity from "./partials/activity";

const Token = ({ }) => {
    const { collection_address, tokenid } = useParams();
    const [details, setDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    async function init() {
        let result = await callW3Api(`/nft/token/details`, { address: collection_address, tokenid: tokenid });
        console.log(result);
        setDetails(result);
        setIsLoading(false);
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <div id="about" className="h-full flex justify-center items-start pb-10 pt-2 text-black dark:text-white w-full">

            <div className="w-full lg:max-w-[1280px] px-5 md:px-10 lg:px-0 lg:rounded-xl ">

                <div className="block lg:flex gap-x-0">
                    <div className="w-full lg:w-5/12 hidden lg:block">
                        <div className="">
                            <Image details={details} />
                        </div>
                    </div>
                    <div className="w-full lg:w-7/12">
                        <div className="rounded-2xl px-5 md:px-10 py-0">
                            <Titles details={details} isLoading={isLoading} collection_address={collection_address} />
                            <div className="mt-8 block lg:hidden">
                                <Image details={details} />
                            </div>
                            {/* {unsupported ? (
                                <Unsupported />
                            ) : (
                                isLoading ? (
                                    <div className="mt-8">
                                        <GetSkeletons paramsArray={['1']} />
                                    </div>
                                ) : (
                                    tokenId == 0 ? ( */}
                            <Price isLoading={isLoading} details={details} collection_address={collection_address} tokenid={tokenid} />
                            {/* ) : (
                                        <div>
                                            <Registered tokenId={tokenId} owner={owner} setOwner={setOwner} name={name} />
                                            <Mappings tokenId={tokenId} owner={owner} name={name} />
                                        </div>
                                    )
                                )
                            )} */}
                        </div>
                        <div className="mt-8 block lg:hidden">
                            <Details details={details} />
                        </div>

                    </div>
                </div>
                <Details details={details} />

                <Activity collection_address={collection_address} tokenid={tokenid} />

            </div>
        </div >
    );

}

export default Token;
