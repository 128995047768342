import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount } from "wagmi";
import { callW3Api } from "../../0.resources/2.js/0.global/3.api/callW3Api";
import { formatinusd } from "../../0.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import CryptoImg from "../../0.resources/3.files/images/crypto.png";
import HomeChart from "./partials/homechart";

const Tokens = ({ }) => {

    return (
        <div className="w-full">
            {/* <div className="w-full h-[300px]" style={{ backgroundImage: `url(${CryptoImg})`, backgroundPosition: "center center", backgroundSize: "cover" }}>

            </div> */}

            <div className="w-full h-[400px]">
                <HomeChart />
            </div>

            <div className="rounded-0 mt-4 bg-dark900">
                <div className="rounded-0 mt-4 bg-dark900 overflow-scroll w-full">
                    <div className="w-[1280px]">
                        <div className="mt-0  px-5 lg:px-14 py-12">
                            <p className="text-white text-4xl font-bold px-6">Top Tokens</p>
                            <p className="text-dark500 text-md mt-2 px-6 font-normal">Top 10 tokens by market cap</p>
                            <Top type={"top"} />
                        </div>
                    </div>
                </div>
                <div className="rounded-0 mt-4 bg-dark900 overflow-scroll w-full">
                    <div className="w-[1280px]">
                        <div className="mt-0  px-5 lg:px-14 py-12">
                            <p className="text-white text-4xl font-bold px-6">Trending Tokens</p>
                            <p className="text-dark500 text-md mt-2 px-6 font-normal">Top 10 tokens by most gains</p>
                            <Top type={"hot"} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

}

export default Tokens;

const Top = ({ type }) => {
    const [items, setItems] = useState([])

    async function getItems() {
        let erc20MarketcapResult = await callW3Api("/tokens/top", { type: type });
        console.log(erc20MarketcapResult);

        let erc20Filtered = erc20MarketcapResult.filter((item, index) => { return erc20MarketcapResult.findIndex(obj => obj.contract_address === item.contract_address) === index; })

        console.log(erc20Filtered);
        setItems(erc20Filtered);
    }

    useEffect(() => {
        getItems();
    }, [])

    return (
        <div className="">
            {items.length != 0 ? (
                <div className="flex items-center justify-between">
                    <div className="w-full pr-10">
                        <Erc20Label type={"erc20"} />
                        <div className="mt-5">
                            <GetErc20Mapping array={items.slice(0, 5)} skip={1} />
                        </div>
                    </div>
                    <div className="w-full pl-10 border-l-2 border-dark800">
                        <Erc20Label type={"erc20"} />
                        <div className="mt-5">
                            <GetErc20Mapping array={items.slice(5, 10)} skip={6} />
                        </div>
                    </div>
                </div>
            ) : (null)}
        </div>
    )
}

const GetErc20Mapping = ({ array, skip }) => {
    console.log(array);
    const mapping = array.map((item, index) =>
        <a key={index} className="flex items-center gap-x-4 py-5 hover:bg-dark800 px-6 rounded-2xl cursor-pointer" href={"/token/" + item.contract_address}>
            <div className="w-[30px]">
                <p className="text-left text-xl font-semibold text-dark500">{skip + index}</p>
            </div>
            <div className="w-11/12 flex items-center gap-x-4 ">
                <div className="w-10 h-10 rounded-3xl">
                    <img src={item.token_logo} className="rounded-lg" />
                </div>
                <div className="flex items-center justify-between w-full">
                    <div className="">
                        <p className="text-white text-lg font-bold flex truncate">{item.token_name}</p>
                        <p className="text-dark500 text-sm">{item.token_symbol}</p>
                    </div>
                    <div className="flex items-center gap-x-8">
                        <div className="flex flex-col items-end">
                            <p className="text-lg font-semibold">{formatinusd(item.price_usd)}</p>
                            <div className="flex items-center gap-x-2">
                                <FontAwesomeIcon icon={["fas", parseFloat(item.price_24h_percent_change) > 0 ? "fa-caret-up" : "fa-caret-down"]} className={parseFloat(item.price_24h_percent_change) > 0 ? "text-green-500" : "text-red-500"} />
                                <p className={parseFloat(item.price_24h_percent_change) > 0 ? "text-green-500 text-sm" : "text-red-500 text-sm"}>{item.price_24h_percent_change}%</p>
                            </div>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={["fas", "fa-chevron-right"]} className="text-dark500" />
                        </div>
                    </div>

                </div>

            </div>
        </a>
    );
    return mapping;
}

const Erc20Label = ({ type }) => {

    return (
        <div className="flex items-center gap-x-4 mt-8 px-6" >
            <div className="w-[30px]">
                <p className="text-left text-md font-semibold text-dark500">{"#"}</p>
            </div>
            <div className="w-11/12 flex items-center justify-between">
                <p className="text-black text-md font-semibold flex truncate text-dark500">{type == "erc20" ? "Token" : "Collection"}</p>
                <div className="flex items-center gap-x-8">
                    <p className="text-black text-md font-semibold flex truncate text-dark500">{type == "erc20" ? "Price" : "Floor Price"}</p>
                    <div className="opacity-0">
                        <FontAwesomeIcon icon={["fas", "fa-chevron-right"]} className="text-dark500" />
                    </div>
                </div>
            </div>
        </div >
    )
}
